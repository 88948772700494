import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";
import Graph from 'vis-react';
import Fullscreen from "react-full-screen";
import { loader } from 'graphql.macro';
import { graphql, compose, withApollo } from 'react-apollo';
import Loading from "../layouts/utilities/Loading";

const getCircles = loader("./CirclesState/graphql/getCircles.graphql");

class CirclesState extends BasicState
{
	addRender = () =>
	{
		console.log(this.props);
		if( this.props.loadingCircles)
			return <Loading />;
		let data = this.props.circles
		let nodes = [], users = [], edges=[], n = 0, team=0;
		data.forEach((e, i) =>
		{
			// add team
			n++;
			team = n;
			nodes.push( { 
				id:n, 
				label: e.title , 
				color:"#FF7777", 
				tid:e._id,
				heightConstraint:true,
				shadow: {enabled:true, },
				shape: "box",
				margin:10
			} );			
			e.members.forEach((ee, ii) =>
			{
				// add member - team
				const user = nodes.filter(eee => eee.uid == ee._id)[0];
				if( user )
				{
					console.log("from: ", user.label, "to: ", e.title);
					edges.push ({ from : user.id, to: team});
					return;
				}
				edges.push ({ from : n + 1, to: team});
				n++;
				const nm = (ee.name || "" ) + " " + (ee.family_name || "");
				nodes.push( {
					id:n, 
					label: nm , 
					color:"#7777FF", uid: ee._id,
					heightConstraint:true,
					shadow: {enabled:true, },
					shape: "box",
					margin:10
				} );
			})
			
		})
		var graph = {
			nodes: nodes,
			edges: edges
		};
		 
		var options = 
		{
			layout: 
			{
				hierarchical: false
			},
			edges: 
			{
				color: '#000000'
			},
			physics: 
			{
				enabled: true,
				barnesHut: 
				{
					gravitationalConstant: -2000,
					centralGravity: 0.009,
					springLength: 200,
					springConstant: 0.7,
					damping: 0.09,
					avoidOverlap: 0.49
				},
				forceAtlas2Based: 
				{
					gravitationalConstant: 50,
					centralGravity: 0.01,
					springConstant: 0.08,
					springLength: 100,
					damping: 0.4,
					avoidOverlap: 0.49
				},
				repulsion: 
				{
					centralGravity: 0.0001,
					springLength: 200,
					springConstant: 0.05,
					nodeDistance: 100,
					damping: 0.09,
					avoidOverlap: 4
				},
				hierarchicalRepulsion: 
				{
					centralGravity: 0.0,
					springLength: 100,
					springConstant: 0.01,
					nodeDistance: 120,
					damping: 0.09
				},
				maxVelocity: 1,
				minVelocity: 0.1,
				solver: 'repulsion',
				stabilization: 
				{
					enabled: true,
					iterations: 1000,
					updateInterval: 100,
					onlyDynamicEdges: false,
					fit: false
				},
				timestep: 0.5,
				adaptiveTimestep: true
			 }
		};
		 
		var events = {
			select: function(event) {
				var { nodes, edges } = event;
			}
		};		
		return <>
			<Fullscreen
				enabled={this.state.isFull}
				onChange={isFull => this.setState({isFull})}
			>
				<div className="position-relative bg-light height_100">
					<Graph
						graph={graph}
						options={options}
						events={events}
						style={{height:"100%", minHeight:500}}
						getNetwork={this.getNetwork}
						getEdges={this.getEdges}
						getNodes={this.getNodes}
						vis={vis => (this.vis = vis)}
					/>
					<div className="btn btn-light btn-sm position-absolute right" onClick={this.goFull}>
						+
					</div>
				</div>
			</Fullscreen>
			<img
				style={{
					display: "flex",
					justifyContent: "stretch",
					alignItems: "stretch",
					width: "100%"
				}}
				src={"/assets/img/pp/circles.jpg"}
				alt=""
			/>
		</>
	}
	goFull = () => 
	{
		this.setState({ isFull: !this.state.isFull });
	}
	getRoute = () =>
	{
		return "backlog";
	}
}
export default compose(
    graphql(getCircles, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getCircles"
		}),
		props: ( p ) => { 
			const data = p.data;
			console.log("getCircles", p);
			return {loadingCircles: data.loading, circles: data.getCircles}},
	}),
	withApollo
)(CirclesState);