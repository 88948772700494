import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";

class CalendarState extends BasicState
{
	myState = () =>
	{
		const iframe = '<iframe src="https://calendar.google.com/calendar/embed?src=dkvec9jg20rm7dddfc6613dl3k%40group.calendar.google.com&ctz=Europe%2FMoscow" style="border: 0" width="100%" height="100%" frameborder="0" scrolling="no"></iframe >';
		return <div dangerouslySetInnerHTML={{ __html: iframe }} style={{
			display: "flex",
			justifyContent: "stretch",
			alignItems: "stretch",
			width: "100%",
			height: "100%"	
		}}/>;
	}
	getRoute = () =>
	{
		return "calendar";
	}
}
export default CalendarState;