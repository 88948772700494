import BasicStateFunctional from "./BasicStateFunctional";
import React, { Component, Fragment, useState, useEffect } from "react";
import gql from 'graphql-tag';
import Graph from 'vis-react';
import { compose } from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import CategoryForm from "./DataTable/CategoryForm";
import {onSaveGql} from "../layouts/schema/ecosystem";


const JitsiState = function (props) {
    const [loading, setLoading] = useState(true);
    const containerStyle = {
      width: '800px',
      height: '400px',
    };
  
    const jitsiContainerStyle = {
      display: (loading ? 'none' : 'block'),
      width: '100%',
      height: '100%',
    }
  
   function startConference() {
    const password = "sdfjiiskdhiewukhfdqewiukhiukdrhqw";
    let isPassworded = false;
    try {
     const domain = 'jitsi.protopia-home.ru';
     const options = {
      roomName: 'ProtopiaJitsiAdminPanel',
      height: 400,
      parentNode: document.getElementById('jitsi-container'),
      interfaceConfigOverwrite: {
       filmStripOnly: false,
       SHOW_JITSI_WATERMARK: false,
      },
      configOverwrite: {
       disableSimulcast: false,
      },
     };
  
     const api = new window.JitsiMeetExternalAPI(domain, options);
     api.addEventListener('videoConferenceJoined', () => {
        if (!isPassworded) {
            setTimeout(()=>{api.executeCommand('password', password);}, 2000);
        }
        console.log('Local User Joined');
      api.executeCommand('displayName', props.user.name + " " + props.user.family_name);
      //api.executeCommand('toggleVideo');
      setLoading(false);
    });
     api.addEventListener('passwordRequired', () => {
        isPassworded = true;
        api.executeCommand('password', password);
     });
    } catch (error) {
     console.error('Failed to load Jitsi API', error);
    }
   }
  
   useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert('Jitsi Meet API script not loaded');
   }, []);
  
    return <div
     style={containerStyle}
    >
     {loading && null}
        <div
          id="jitsi-container"
          style={jitsiContainerStyle}
        />
    </div>;
}

function JitsiStateContainer(props) {
  const [show, setShow] = useState(false);
  return <BasicStateFunctional {...props
  } >
  <div>
   { show ? 
      <JitsiState {...props}/> : <h2>
        <a onClick={()=>{setShow(true);}}>Включить конференцию</a>
      </h2>
    }
  </div>
  </BasicStateFunctional>;
}

export default compose(

    withApollo,
    withRouter
)(JitsiStateContainer);