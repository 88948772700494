import React, {Component, Fragment} from "react";
import { Button, ButtonGroup, Classes, Intent, Icon, Card } from "@blueprintjs/core";
import {__} from "../../layouts/utilities/i18n";
import CurrentUser from "./CurrentUser";
import CategoryForm from "../DataTable/CategoryForm";
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";

class PlaceEvent extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			...props,
			height:235,
			d_height:"auto",
			isOpen:0
		}
	}
	componentDidUpdate(prevProps, prevState)
	{
		//console.log( this.props._id );
		const d_height = $("[e_id=" + this.props._id + "]").innerHeight();
		/*
		console.log( 
			prevState.d_height, 
			d_height, 
			prevState.d_height !== d_height && d_height > 0 && prevState.d_height > 0 || prevState.d_height=="auto" 
		);
		*/
		if( prevState.d_height !== d_height && d_height > 0 && prevState.d_height > 0 || prevState.d_height=="auto" )
			this.setState({ d_height });
	}
	render()
	{
		const {height, isOpen, title, description, start_date, end_date, _id} = this.state;
		//console.log(this.state);
		const edit_btns = CurrentUser.intersectionRole(['admin', 'moderator']).length > 0 
			? 
				<ButtonGroup>
					<Button
						minimal={true}
						onClick={this.onEdit}
					>
						<Icon icon="edit" />
					</Button>
				</ButtonGroup>
			: "";
		const start_d 	= start_date
			? "⏲ " + moment( start_date ).format('D MMMM YYYY h:mm:ss')
			: "";
		const end_d		= end_date
			? " - " + "⏲ " + moment( end_date ).format('D MMMM YYYY h:mm:ss')
			: "";
		return <Fragment>
			<div className="float-right">
				{edit_btns}
			</div>
			<div className="p-0 mb-2">
				<div className="font-weight-bold" style={{minHeight:30}}>{ title }</div>
				<div 
					style={{ height: isOpen ? 0 : this.state.d_height }} 
					className="card-events-title" e_id={_id} 
				>
					<div className="my-3">{ description }</div>				
					<div className="text-secondary">
						{ 
							start_date 
								? 
								start_d + end_d
								: 
								__("Date not defined")}
					</div>
				</div>
			</div>
			<Card 
				fill={"true"} 
				className={isOpen ? "layout-data-card p-0 mb-1 py-3" : "layout-data-card p-0"} 
				style={{ height: isOpen ? height : 0, boxShadow: "none" }}
				interactive={false} 
			>
				
				<CategoryForm 
					{...this.state}
					ID={this.state._id}							
					data={ this.state }	
					data_type={ "PlaceEvent" }						
					onChange={this.onChange}
					on={this.onChange}
					onSave={(state, _id) => this.onSave( state, _id)}
					onDelete={this.onDelete}
					onClose={ this.onClose }
					vertical={false}
					isHiddenSave={false}
					isHiddenClose={true}
					isHiddenDelete={false}
					isOpen={ true } 
				/>				
			</Card>
		</Fragment>
	}
	onChange=(field, value, id) =>
	{
		console.log(field, value, id);
		let state = {...this.state};
		state[value] = field;
		this.setState( state, console.log( this.state ) );
	}
	onSave( state, _id )
	{
		delete state.isOpen;
		delete state.height;
		delete state.field;
		delete state.place_id;
		this.setState(state, console.log( state, _id));
		this.props.onSave( state, _id  );
		this.setState({ isOpen: !this.state.isOpen });
	}
	onEdit = () =>
	{
		this.setState({ isOpen: !this.state.isOpen });
		//this.props.onEvent(this.state._id, this.state.place_id)
	}
	onDelete = () =>
	{
		//this.props.onEvent(this.state._id, this.state.place_id)
		this.props.onDelete( this.props._id );
	}
}
export default PlaceEvent;