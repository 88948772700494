import React, {Component, Fragment} from "react";
import Moment from 'react-moment';
import 'moment/locale/ru';

class Post extends Component
{
	render()
	{
		const { post_text, user_first_name, user_second_name, post_time} = this.props; 
		return <div className={"row px-3 pt-1 pb-2 " + (this.props.isCurrent ? "bg-light" : "")}>
			<div className="col-12">
				{ user_first_name + " " + user_second_name }:
			</div>
			<div className="col-12 font-weight-bold">
				{ post_text }
			</div>
			<div className="col-12 small opacity_5">
				<Moment locale="ru" format="D MMMM YYYY">
					{new Date( post_time )}
				</Moment>
			</div>
		</div>
	}
}
export default Post;