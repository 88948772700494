import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";

class DriversState extends BasicState
{
	myState = () =>
	{
		const iframe = '<iframe  src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTVQ-WeXUME6oqAhG7CI20StlIv2C8_HqQhB23VavXXt4-ICMdd7KPBvLdXNUFvLK3GLxP1r2fD8BOR/pubhtml?widget=true&amp;headers=false" style="border: 0" width="100%" height="100%" frameborder="0" scrolling="no"></iframe>';
		return <div dangerouslySetInnerHTML={{ __html: iframe }} style={{
			display: "flex",
			justifyContent: "stretch",
			alignItems: "stretch",
			width: "100%",
			height: "100%"	
		}}/>;
	}
	getRoute = () =>
	{
		return "backlog";
	}
}
export default DriversState;