import React from 'react';
import ReactDOM from 'react-dom';

import LayoutApp from './LayoutApp';


ReactDOM.render(
    <LayoutApp />,
    document.getElementById('root')
);

