import React, {Component, Fragment} from "react";

class ChatHead extends Component
{
	render()
	{
		return <div className="row">
			<div className="col-12 lead">
				{this.props.title}
			</div>
		</div>
	}
}
export default ChatHead;