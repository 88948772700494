import React, {Component, Fragment} from "react";
import { Tabs, Tab, Button, Classes, Dialog, Intent, Tooltip, Callout, Card, FormGroup, TextArea } from "@blueprintjs/core";
import {__} from "../../layouts/utilities/i18n";
import translitterate from "../../layouts/utilities/translitterate";
import Moment from 'react-moment';
import moment from 'moment';
import {String, URL, DateTime, Radio, Geo, TextField, Array} from "../../layouts/FieldInput";
import cyrillicToTranslit from "cyrillic-to-translit-js";

class NewEventForm extends Component
{
	state={
		...this.props
	}
	render()
	{
		//console.log(this.state);
		return <div>					
			<String
				field={ "title" } 
				title={ __("Title") } 
				editable={ true } 
				value={ this.state.title }
				vertical={ false }		
				on={this.onTitle}
				autoFocus={true}
			/>
			<TextField
				field={ "description" } 
				title={ __("Description") } 
				editable={ true } 
				on={this.onDescription}
				value={this.state.description}
			/>
			<DateTime
				field={ "start_date" } 
				title={ __("Start Date") } 
				editable={ true } 
				value={ this.state.start_date }
				vertical={ false }		
				on={this.onStart_date}
			/>
			<DateTime
				field={ "end_date" } 
				title={ __("Finish Date") } 
				editable={ true } 
				value={ this.state.end_date }
				vertical={ false }		
				on={this.onEnd_date}
			/>
			{/*
			<Radio
				field={ "external_system" } 
				title={ __("Event Observe") } 
				editable={ true } 
				values={["WP_fest", "TimePad"]}
				value={ this.state.external_system }
				vertical={ false }		
				on={this.onExternal_system}
			/>
			}
			{
				this.state.geo || this.state.new_mark_coords || false
				?
				<Array
					field={ "geo" } 
					title={ __("Geo") } 
					editable={ false } 
					value={ this.state.geo || this.state.new_mark_coords }
				/>
				:
				null
			*/}
		</div>
	}
	onTitle = evt =>
	{
		//console.log("title", translitterate().transform(evt, "-").toLowerCase() );	
		this.setState({ title: evt });	
		this.setState({ domain:  translitterate().transform(evt, "-").toLowerCase() });	
		this.props.on("title", evt);
		this.props.on("domain", translitterate().transform(evt, "-").toLowerCase());
	}
	onDescription = evt =>
	{
		//console.log("description", evt);	
		this.setState({ description: evt });	
		this.props.on("description", evt);
	}
	onStart_date = evt =>	
	{
		//console.log("start_date", evt);	
		this.setState({ start_date: evt });		
		this.props.on("start_date", evt);
	}
	onEnd_date = evt =>	
	{
		console.log("end_date", evt);	
		this.setState({ end_date: evt });		
		this.props.on("end_date", evt);
	}
	onExternal_system = evt =>	
	{
		//console.log("external_system", evt);	
		this.setState({ external_system: evt });
		this.props.on("external_system", evt);
	}
}
export default NewEventForm;