import React, {Component, Fragment} from "react";
import {__} from "../../layouts/utilities/i18n";
import Loading from "../../layouts/utilities/Loading";
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import { AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip } from "@blueprintjs/core";
import NewTeamForm from "./NewTeamForm";
import NewPlaceForm from "./NewPlaceForm";
import NewEventForm from "./NewEventForm";

import { compose } from 'react-apollo';
import {withRouter} from "react-router";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import axios from 'axios';

const getCurrentUser = loader("./graphql/getCurrentUser.graphql");

class NewDialog extends Component
{
	constructor(props)
	{
		super(props);
		//console.log(this.props);
		this.state = { 
			isWPFestOpen:0,
			WPFest_status:"none",
			WPFest_arguments:{},
			title:"",
			isOpen:false
		}
	}
	componentWillReceiveProps ( nextProps )
	{
		if(typeof nextProps.isOpen !== "undefined")
		{
			this.setState(nextProps);
		}
		if(this.props.getCurrentUser.loading !== nextProps.getCurrentUser.loading)
		{
			//console.log(nextProps.getCurrentUser.getCurrentUser._id);
			this.setState({				
				WPFest_status:"login",
				WPFest_arguments: nextProps.getCurrentUser.getCurrentUser
			});
		}
	}
	forms = {
		"NewTeamForm" : NewTeamForm,
		"NewPlaceForm" : NewPlaceForm,
		"NewEventForm" : NewEventForm,
	}
	render()
	{


		//console.log( this.props.getCurrentUser.email );
		if( this.props.getCurrentUser.loading )
			return <Loading />;
		let Form;
		//console.log(this.props.place_types);

		switch(this.props.DNDType)
		{

			case "Event":
				Form = this.forms["NewEventForm"]
				break;
			case "Team":
				Form = this.forms["NewTeamForm"]
				break;
			case "Place":
			default:
				Form = this.forms["NewPlaceForm"]
				break;
		}
		const footer_btns = <ButtonGroup>
			<Button
				icon="plus"
				text={__("Create")}
				onClick={this.onCreate}
			/>
			<Button
				icon="minus"
				text={__("Cancel")}
				onClick={this.props.onCancel}
			/>
		</ButtonGroup>
		return <Fragment>
			<Dialog
				icon="map-marker"
				isOpen={ this.state.isOpen }
				onClose={ this.props.toggle }
				title={<div>{ __(this.state.title) }</div>}
			>
				<div className="p-4 cluster-dialog">
					<Form 
						on={this.onChangeParam}
						{...this.state}
						{...this.props.place_types}
					/>
				</div>
				<div className="p-4 cluster-dialog-footer">
					{footer_btns}
				</div>
			</Dialog>
		</Fragment>
	}
	onChangeParam = (value, param) =>
	{
		console.log(param, value);
		let state ={};
		state[param] = value;
		this.setState(state);
	}
	onCreate = () => 
	{
		if( this.props.DNDType == "Event" && this.state.external_system == "WP_fest")
		{
			const args = {
				post_title 	: this.state.title,
				domain		: this.state.domain,
				site		: "8",
				date		: this.state.start_date,
				markers		: this.state.geo,
				email		: this.props.getCurrentUser.getCurrentUser.email
			}
			//console.log( args );
			//console.log( this.state );
			this.setState(
				{ WPFest_status: "lp_create_site", WPFest_arguments: args }
				, ()=> this.setState({WPFest_status:"none"})
			);
		}
		else
		{
			this.props.onCreate( this.state, this.props.DNDType );
		}
	}
	toggle = () =>
	{
		this.setState({isOpen:!this.state.isOpen});
	}
}

export default compose(
    graphql( getCurrentUser, {"name" : "getCurrentUser"} ),
	withApollo,
	withRouter
)(NewDialog);