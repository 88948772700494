import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";
import {sprintf} from "../layouts/utilities/sprintf";
import {__} from "../layouts/utilities/i18n";

class DomainState extends BasicState
{
	myState = () =>
	{
		return <div className="container">
		<div className="row">
			<div className="col-4">
				<div className="page-title text-center mb-3">
					Операционный бэклог
				</div>
			</div>
			<div className="col-4">
				<div className="page-title text-center mb-3">
					Название домена:
				</div>
				<div className="page-title text-center mb-3">
					Ведущий мотив:
				</div>
				<div className="page-title text-center mb-3">
					Критерии оценки:
				</div>
			</div>
			<div className="col-4">
				<div className="page-title text-center mb-3">
					Управленческий бэклог
				</div>
			</div>
		</div>
	</div>;
	}
	getRoute = () =>
	{
		return "backlog";
	}
}
export default DomainState;