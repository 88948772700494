import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";

class BacklogState extends BasicState
{
	myState = () =>
	{
		const iframe = '<iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vR4TAnLqpEFLrwfOccoZbWDEVhrpTy66Wco96YRpCbjLqrtx1JfmluqAA8djiolhf14EEQjZ_hvim_x/pubhtml?widget=true&amp;headers=false" style="border: 0" width="100%" height="100%" frameborder="0" scrolling="no"></iframe>';
		return <div dangerouslySetInnerHTML={{ __html: iframe }} style={{
			display: "flex",
			justifyContent: "stretch",
			alignItems: "stretch",
			width: "100%",
			height: "100%"	
		}}/>;
	}
	getRoute = () =>
	{
		return "backlog";
	}
}
export default BacklogState;