import BasicStateFunctional from "./BasicStateFunctional";
import React, { Component, Fragment, useContext } from "react";
import gql from 'graphql-tag';
import { compose } from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import CategoryForm from "./DataTable/CategoryForm";
import {onSaveGql} from "../layouts/schema/ecosystem";
import Moment from 'react-moment';

const NewsListState = function (props) {
    let {loading, data} = useQuery(gql`
        query getNewsItems{
            getNewsItems {
                _id
                title
                description
                date
                project {
                    _id
                    title
                }
                circle {
                    _id
                    title
                }
                author {
                    _id
                    full_name
                }
            }
        }
    `, {client: props.client})
    if (loading) {
        return null;
    }
   
    let sortedNews = data.getNewsItems.sort((news1, news2) => news1.date > news2.date ? -1 : 1);

    return <BasicStateFunctional {...props
    } >
        <div className="position-relative bg-light height_100">
            <table class="table mb-5">
                <tr>
                    <th>Название</th>
                    <th>Описание</th>
                    <th>Дата</th>
                    <th>Круг</th>
                    <th>Проект</th>
                    <th>Автор</th>
                </tr>
                {sortedNews.map(newsItem =>
                    <tr>
                        <td><b style={{fontWeight: 'bold'}}>{newsItem.title}</b></td>
                        <td>{newsItem.description}</td>
                        <td><Moment locale="ru" format="D MMMM YYYY, h:mm">
                            {new Date(newsItem.date)}
                        </Moment></td>
                        <td>{newsItem.circle ? newsItem.circle.title : null}</td>
                        <td>{newsItem.project ? newsItem.project.title : null}</td>
                        <td>{newsItem.author.full_name}</td>
                    </tr>
                )}
            </table>
        </div>
    </BasicStateFunctional>;
}

export default compose(

    withApollo,
    withRouter
)(NewsListState);