import BasicStateFunctional from "./BasicStateFunctional";
import React, { Component, Fragment, useContext } from "react";
import gql from 'graphql-tag';
import Graph from 'vis-react';
import { compose } from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import CategoryForm from "./DataTable/CategoryForm";
import {onSaveGql} from "../layouts/schema/ecosystem";


const QuizState = function (props) {
    console.log(props);
    let {loading, data} = useQuery(gql`
        query getQuiz{
            getQuiz(id:"${props.match.params.id}") {
                start_page {
                _id
                }
                pages {
                _id
                description
                answers {
                    _id
                    title
                    target_page {
                    _id
                    description
                    }
                }
                }
            } 
        }
    `, {client: props.client})
    if (loading) {
        return null;
    }
   
    let graph = { nodes: {}, edges: [] }
    let options = {

        interaction: {
        },
        physics: {
            solver: 'barnesHut',
            barnesHut: {
                gravitationalConstant: -4000,
		        springLength: 400,
            },
            stabilization: {
                enabled: true,
                iterations: 0,
                fit: false
            },
            stabilization: {
                enabled: true,
                iterations: 0,
                fit: false
            },
        }
    }
    data.getQuiz.pages.forEach(e => {
        graph.nodes[e._id] = ({
            id: e._id,
            type: 'page',
            label: e.description.slice(0, 40),
            font: {
                size: 15,
                color: "white",
            },
            color: "red"
        })
        if (e._id == data.getQuiz.start_page._id) {
            graph.nodes[e._id].color = "green";
        }
        if (e.answers) {
            e.answers.forEach((e2) => {
                graph.edges.push({
                    from: e._id,
                    to: e2.target_page._id,
                    label: e2.title,
                    smooth: {
                        enabled: true,
                    },
                    nocolor: {
                        color: "white",
                        highlight: "white"
                    }
                })
            })
        }
    });

    
    let nodes_object = graph.nodes;
    graph.nodes = Object.values(graph.nodes);

    let events = {
        doubleClick : function(e) {
            if (e.nodes[0]) {
                let node = nodes_object[e.nodes[0]];
                if (node.type == "page") {
                    props.history.push("/resources/quiz_page/" + node.id);
                }
            }
        }
    }

    return <BasicStateFunctional title={data.getQuiz.title} {...props
    } >
        <div className="position-relative bg-light height_100">
            <Graph graph={graph} options={options} events={events}
                style={{height:"500px"}}
            />
        </div>
    </BasicStateFunctional>;
}

export default compose(

    withApollo,
    withRouter
)(QuizState);