import React, {Fragment} from "react";
import BasicState from "../layouts/BasicState";
import {__} from "../layouts/utilities/i18n";
import Loading from "../layouts/utilities/Loading";
import { graphql, compose, withApollo } from 'react-apollo';
import { loader } from 'graphql.macro';
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import {withRouter} from "react-router";
import { NavLink } from 'react-router-dom';
import { AnchorButton, Button, Classes, Dialog, Intent, Tooltip, Popover, Position } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";
import {AppToaster} from "../layouts/utilities/blueUtils";

import NewDialog from "./mapState/NewDialog";
import ClusterDialog from "./mapState/ClusterDialog";
import YandexMap from "./mapState/YandexMap";
import PlaceTabs from "./mapState/PlaceTabs";
import EventTabs from "./mapState/EventTabs";
import TeamTabs from "./mapState/TeamTabs";


const getPlaces = loader("./mapState/graphql/getPlaces.graphql");
const getPlaceTypes = loader("./mapState/graphql/getPlaceTypes.graphql");
const getEvents = loader("./mapState/graphql/getEvents.graphql");
const getTeams = loader("./mapState/graphql/getTeams.graphql");
const changeTeam = loader("./mapState/graphql/changeTeam.graphql");
const changePlace = loader("./mapState/graphql/changePlace.graphql");


class MapState extends BasicState
{
	stateDidMount() 
	{
		
		
	}
	beforeRender()
	{
		
	}
	getRoute = () =>
	{
		return "map";
	}
	
	onNew = data =>
	{
		console.log(data);
		this.setState({ startDND : data, isClusterOpen:false});
	}
	onFinishNew = () =>
	{
		this.setState({ startDND : { hideMarker:true, isDragging:false } });
	}
	onStartCreate = data =>
	{
		console.log("onStartCreate", data);
		this.setState({
			isClusterOpen:false,
			isNewOpen:true,
			newTitle: data.title,
			newData:{geo: data.new_mark_coords, DNDType: data.DNDType}
		});
	}	
	onCreateTeam = data =>
	{
		//console.log(data);
		let pr = {};
		pr.title = data.title;
		pr.description = data.description;
		if(data.id)
			pr.id = data.id;
		this.props.changeTeam({
			variables: pr,
			update: (store, { data: { changeTeam } }) =>
			{
				console.log( changeTeam )
				this.props.onClose();
			}
		})
	}
	onCreate = (data, DNDType) =>
	{
		console.log(data);
		let pr = {};
		pr.title = data.title;
		if(data.id)
			pr.id = data.id;
		switch(DNDType)
		{
			case "Team":
				pr.description = data.description;
				this.props.changeTeam({
					variables: pr,
					update: (store, { data: { changeTeam } }) =>
					{
						console.log( changeTeam )
						this.setState({isNewOpen : !this.state.isNewOpen})
					},
					refetchQueries: [
						{ query: getTeams, variables: {}}
					]
				});
				break;
			case "Place":
			    console.log(data);
				pr.geo 				= data.geo;
				pr.address 			= data.address;
				pr.description 		= data.description;
                pr.type_new_id =  data.type_new ? data.type_new._id : null;
				this.props.changePlace({
					variables: pr,
					update: (store, { data: { changePlace } }) =>
					{
						console.log( changePlace );
						this.setState({isNewOpen : !this.state.isNewOpen});
					
					},
					refetchQueries: [
						{ query: getPlaces, variables: {}}
					]
				});
				
				break;
			case "Event":
				
				break;
		}
	}
	onCancelCreate = () =>
	{
		this.setState({
			startDND: {
				hideMarker:true, 
				isDragging:false,
				DNDIconX:1,
				DNDIconY:1,
				deletePlace:true
			},
			isNewOpen:false
		});
	}
	
	addRender()
	{
		if( this.props.loadingPlaces || this.props.loadingTeams || this.props.loadingEvents || this.props.loadingPlaceTypes )
			return <Loading />;
		//console.log(this.props.places);
		let legends = this.props.placeTypes.map((e, i) =>
		{
			return <span className="mr-2" style={{color: e.color}} key={i}>
				{ __(e.title) }
			</span>
		});
		return <Fragment>
			<YandexMap
				{...this.props}
				context={this}
				startDND={ this.state.startDND ? this.state.startDND : { hideMarker:true, isDragging:false } }
				onFinishNew={this.onFinishNew}
				onStartCreate={this.onStartCreate}
			/>	
			<div className="font-weight-bold m-1">
				{legends}
				<div className="float-right ">					
					<Popover
						position={Position.TOP_LEFT}
						content={
							<div className="square">
								<small className="p-2 pb-3 text-center">
									{__("Copy this text and paste in your html-code.")}
								</small>
								<textarea
									rows={10}
									autofocus={true}
									defaultValue={'<iframe width="800" height="600" src="' + process.env.REACT_APP_URL + '"/></iframe>'}
									disabled={false}
									style={{width:"100%", padding:10}}
								>
									
								</textarea>
							</div>						
						}
					>
						<Button minimal={true} className="font-weight-normal">
							{ __("Copy iFrame code") }
						</Button>
					</Popover>
				</div>
			</div>
			<div className="position-absolute">
				<NewDialog
					isOpen={this.state.isNewOpen}
					title={this.state.newTitle}
					{...this.state.newData}
					place_types={this.props.place_types}
					onCreate={this.onCreate}
					onCancel={this.onCancelCreate}
					toggle={() => this.setState({isNewOpen : !this.state.isNewOpen}) }
				/>
				<ClusterDialog
					toggleDialogCluster={this.toggleDialogCluster}
					onNew={this.onNew}
					getPlaceDialog={this.getPlaceDialog}
					getEventDialog={this.getEventDialog}
					getTeamDialog={this.getTeamDialog}
					clusterTitle={this.state.clusterTitle}
					cluster={this.state.cluster}
					isClusterOpen={this.state.isClusterOpen}
					onStartCreate={this.onStartCreate}
				/>
				<Dialog
					icon="map-marker"
					isOpen={ this.state.isOpen }
					onClose={ this.toggleDialog }
					title={<div>
						<span
							className=" mr-3"
							style={{
								color: this.state.place && this.state.place.type_new
									? this.state.place.type_new.color
									: "#777"
							}}
						>
							{__(this.state.place && this.state.place.type_new ? this.state.place.type_new.title : "Place")}
						</span>
						{ this.state.dialogTitle }
					</div>}
					style={{zIndex:1000}}
				>
					<div className="pt-dialog-body p-4">
						<PlaceTabs 
							onClose={ this.toggleDialog } 
							onEvent={ this.getEventDialog }
							onMerged={this.onMerged}
							{ ...this.state.place }
						/>
					</div>
				</Dialog>
				<Dialog
					icon="timeline-events"
					isOpen={ this.state.isOpenEvent }
					onClose={ this.toggleDialogEvent }
					title={<div>
						<span className="font-weight-light mr-3">
							{__(this.state.event && this.state.event.external_system == "wp_fest" ? "Festival" : "Event")}
						</span>
						{ this.state.dialogEventTitle }
					</div>}
					style={{zIndex:1010}}
				>
					<div className="pt-dialog-body p-4">
						<EventTabs
							onClose={ this.toggleDialogEvent } 
							{ ...this.state.event }
						/>
					</div>
				</Dialog>
				<Dialog
					icon="person"
					isOpen={ this.state.isOpenTeam }
					onClose={ this.toggleDialogTeam }
					title={<div><span className="font-weight-light mr-3">{__("Team")}</span>{ this.state.dialogTeamTitle }</div>}
					style={{zIndex:1050}}
				>
					<div className="pt-dialog-body p-4">
						<TeamTabs
							onClose={ this.toggleDialogTeam } 
							{ ...this.state.event }
						/>
					</div>
				</Dialog>
			</div>
		</Fragment>
	}
	
	getPlaceHeader = ((el, isBabel=false) =>
	{
		const flag = el.is_events_active ? '<div class="_flag float-right"></div>' : '';
		const type_new = el.type_new || {}
		return isBabel 
			?
			<Fragment>
				<span className='small text-primary'>
					<i className='fas fa-map-marker-alt'/> 
					{ __(type_new.title) }
				</span>
				{el.title}
				{flag}
			</Fragment>
			:
			"<span class='small' style='color:" + type_new.color + "'><i class='fas fa-map-marker-alt'></i> " + __(type_new.title) + "</span> " + el.title + flag
	})
	getEventHeader = ((el, isBabel=false) =>
	{
		return isBabel 
			?
			<Fragment>
				<span className='small text-danger'><i className='fas fa-calendar-alt'/> 
					{__("Event")}
				</span>
				{el.title}
			</Fragment>
			:
			"<span class='small text-danger'><i class='fas fa-calendar-alt'></i> " + __("Event") + "</span> " + el.title
	})
	getTeamHeader = ((el, isBabel=false) =>
	{
		return isBabel 
			?
			<Fragment>
				<span className='small text-success'><i className='fas fa-users'/> 
					{__("Team")}
				</span>
				{el.title}
			</Fragment>
			:
			"<span class='small text-success'><i class='fas fa-users'></i> " + __("Team") + "</span> " + el.title
	})
	getPlaceBody = (data, events) =>
	{
		return "<div class='my-1'><a href='javascript:window.getPlaceDialog(\"" + data._id + "\");' class='btn btn-primary'>" + __("More") + "</a></div>";
	}
	getEventBody = (element, place) =>
	{
		const date = element.start_date ? moment(element.start_date ).format('D MMMM YYYY') : __("Date not defined");
		const descr = "<b>" + (element.description ? __(element.description) : __(element.title)) + "</b> ";
		const args = place ? " \"" + element._id + "\", \"" + place._id + "\"" : "\"" + element._id + "\""
		const link = "<div class='my-1'><a href='javascript:window.getEventDialog(" + args +");' class='btn btn-danger'>" +
			__("More") +	
		"</a></div>";
		const members = element.members && element.members.length 
			? 
			"<div>"+__("Members:") + "<ul>" + element.members.map((e) => "<li>" + e.name + "</li>").join("") + "</ul></div>"
			:
			"";
		return date + link
	}
	getEventFooter = e =>
	{
		//console.log(e.external_url);
		//const date = e.start_date ? moment( e.start_date).format("DD.MM.YYYY") : __("Date not defined");
		const _link = ( e.external_url ? "<a href='"+e.external_url+"' target='_blank'>" +__("More information") +"</a>" : "");
		return "<div>" + _link + "</div>";
	}
	
	
	getPlaceLink = id =>
	{
		const location = {
		  pathname: '/place/' + id,
		  state: { fromDashboard: true }
		}
		this.props.history.push(location);
	}
	getPlaceDialog = id =>
	{
		const place = this.props.places.filter(e => e._id == id)[0];
		if(!place) return;
		//console.log(place);
		this.setState({ isOpen:!this.state.isOpen, dialogTitle : place.title, place, isClusterOpen: false });
	}
	toggleDialog = () => this.setState({isOpen:!this.state.isOpen });
	
	getTeamDialog = ( id, placeID ) =>
	{
		const arr = placeID ? this.props.teams.filter( e => e._id == placeID )[0].teams : this.props.teams;
		const event = arr.filter( e => e._id.toString() == id.toString() )[0];
		if(!event) return;
		this.setState({ isOpenTeam:!this.state.isOpenTeam, dialogTeamTitle : event.title, event, isClusterOpen:false, isOpen:false});
	}
	toggleDialogTeam = () => this.setState({isOpenTeam:!this.state.isOpenTeam });
	
	getEventDialog = ( id, placeID ) => 
	{
		const arr = placeID ? this.props.events.filter( e => e._id == placeID )[0].events : this.props.events;
		const event = arr.filter( e => e._id.toString() == id.toString() )[0];
		if(!event) return;
		this.setState({ isOpenEvent:!this.state.isOpenEvent, dialogEventTitle : event.title, event, isClusterOpen:false, isOpen:false});
	}
	toggleDialogEvent = () => this.setState({isOpenEvent:!this.state.isOpenEvent });
	
	onMerged = () =>
	{
		this.toggleDialog();
	}
	getClusterDialog( geoObjects, clusterTitle="Cluster" )
	{
		let cluster = Array.isArray(geoObjects) ? geoObjects.map((e, i) => {
			return {...e.properties._data};
		}) : null;
		console.log( cluster );
		this.setState({ 
			isClusterOpen : (cluster ? !this.state.isClusterOpen : false), 
			clusterTitle, 
			cluster 
		});
	}
	toggleDialogCluster = () => this.setState({ isClusterOpen:!this.state.isClusterOpen });
	
	rightPanel()
	{
		const st = this.ifModal() ? this.modalMenu() : {};
		const humburger = this.ifModal() 
			? 
			<div className="pointer" onClick={ () => this.setState({ isHumburger:!this.state.isHumburger }) } >
				<i 
					className={"fas " + (this.state.isHumburger ? "fa-times" : "fa-bars") + " pointer"}
					style={{ margin: "0 11px", fontSize: "1.2rem" }}
					
				/> 
			</div>
			: 
			null;
		return <Fragment>
			<div className=" map-menu" style={st} id="map-menu">
				<div className="btn btn-primary btn-sm mr-3" onClick={this.addNewPlace}>
					<i className='fas fa-plus mr-2'/> {__("add new Place")}
				</div>
				<div className="btn btn-primary btn-sm " onClick={this.allPlaces}>
					<i className='fas fa-map-marker-alt mr-2'/> {__("Places")}
				</div>
				<div className="btn btn-success btn-sm " onClick={this.allTeams}>
					<i className='fas fa-users mr-2'/> {__("Teams")}
				</div>
				{this.state.panelHtml}
			</div>
			{ humburger }
		</Fragment>
	}
	addNewPlace = env =>
	{
		AppToaster.show({
			intent: Intent.SUCCESS,
			icon: "tick",
			message: __("Show place on map")
		});
		
		this.setState({ isHumburger:false });
		this.onNew({
			x			: env.clientX,
			y			: env.clientY,
			DNDType		: "Place",
			DNDStyle	: {backgroundColor:"#3b5998"},
			isDragging	: true
		})
	}
	allPlaces = () =>
	{
		const cluster = this.props.places.map(e => {
			console.log(e);
			return { data_type:"place", data_id:e._id, balloonContentHeader:this.getPlaceHeader( e ) , ...e}
		});
		//console.log(cluster);
		this.setState({ 
			isClusterOpen : !this.state.isClusterOpen, 
			clusterTitle:  __("All Places"), 
			cluster	
		});
	}
	allEvents = () =>
	{
		const cluster = this.props.events.map(e => {
			return { data_type:"event", data_id:e._id, balloonContentHeader:this.getEventHeader( e ), ...e}
		});
		//console.log(cluster);
		this.setState({ 
			isClusterOpen : !this.state.isClusterOpen, 
			clusterTitle:  __("All Events"), 
			cluster 		
		});
	}
	allTeams = () =>
	{
		const cluster = this.props.teams.map(e => {
			return { data_type:"team", data_id:e._id, balloonContentHeader:this.getTeamHeader( e ), ...e}
		});
		//console.log(cluster);
		this.setState({ 
			isClusterOpen : !this.state.isClusterOpen, 
			clusterTitle:  __("All Teams"), 
			cluster 			
		});
	}
	
	ifModal()
	{
		// console.log(window.screen.width)
		return window.screen.width < 750;
	}
	modalMenu()
	{
		if(this.state.isHumburger)
			return {height:"140px", overflow: "none", "z-index": 10000}
		else
			return {height:"40px", overflow: "auto", "z-index": 10000}
	}
}
	
/* export default MapState; */

export default compose(
    graphql(getPlaces, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getPlaces"
		}),
		props: ( p ) => { 
			const data = p.data;
			//console.log("getPlaces", p);
			return {loadingPlaces: data.loading, places: data.getPlaces}},
	}),
	graphql(getEvents,
	{
		options: ( props ) => ({
			variables: { },
			name: "getEvents"
		}),
		props: ( p ) => { 
			const data = p.data;
			//console.log("getEvents", p);
			return {loadingEvents: data.loading, events: data.getEvents}		
		},
	}),
	graphql(getTeams, 
	{
		options: ( props ) => ({
			variables: { },
			name: "getTeams"
		}),
		props: ( p ) => { 
			const data = p.data;
			//console.log("getTeams", p);
			return {loadingTeams: data.loading, teams: data.getTeams}		
		},
	}),
    graphql(getPlaceTypes, {
            options: ( props ) => ({
                variables: { },
                name: "getPlaceTypes"
            }),
            props: ( p ) => {
                const data = p.data;
                //console.log("getPlaceTypes", p);
                return {loadingPlaceTypes: data.loading, placeTypes: data.getPlaceTypes}
                },
    }),
	graphql(changePlace, {"name": "changePlace"}),
	graphql(changeTeam, {"name": "changeTeam"}),
	withApollo,
	withRouter
)(MapState);