import React, {Fragment} from "react";
import BasicState from "../layouts/BasicState";
import Propfile from "./profile/Profile";
import {__} from "../layouts/utilities/i18n";
import {AppToaster} from "../layouts/utilities/blueUtils";
import { Intent } from "@blueprintjs/core";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {compose} from "recompose";
import { loader } from 'graphql.macro';
import {Query, withApollo, graphql, Mutation} from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
import Loading from "../layouts/utilities/Loading";
import {
	getQueryArgs,
	queryCollection,
	getMutationArgs,
	mutationEditName,
	getInputTypeName,
	mutationEdit, getQueryName,
} from "../layouts/schema";
import {userModel} from "../layouts/user";

class ProfileState extends BasicState
{
	
	basic_state_data2() 
	{
		return {name:"", email: "", password: ""};
	}

	render()
	{

		const data_type = userModel();
		const query_name =  getQueryName(data_type);
		const query_args = getQueryArgs(data_type)

		const query = queryCollection( data_type, query_name, query_args );


		const mutation_name =  mutationEditName(data_type);
		const input_type_name = getInputTypeName( data_type );
		const mutation_args = getMutationArgs( data_type );
		console.log(mutation_args);


		const mutation = mutationEdit(null, mutation_name, input_type_name, mutation_args );


		return <Query query={query}>
			{
				({ loading, error, data, client}) =>
				{
					if( loading)
					{
						return <Loading/>;
					}
					if(data)
					{
						const user = data[query_name] || {};
						return <Mutation mutation={mutation}>
							{( m_change, { data } ) =>
								this.profile(user, m_change)
							}
						</Mutation>;
					}
					if(error)
					{
						return error.toString();
					}
				}
			}
		</Query>;
	}

	profile(user, m_change)
	{
		return  <div className="layout-state">
			<div className="layout-state-head">
				<span className={ "layout-state-logo " + this.state.route.icon } />
				<div className="layout-state-title">
					{ __( this.state.route.title ) }
				</div>
			</div>
				<Propfile 
					user={user}	
					onEditField={this.onEditField}
					onChange={(state) => this.onChange(state, m_change)}
				/>
				
			</div>
	}
	onEditField = state =>
	{		
		console.log(state);
	}
	getRoute = () =>
	{
		return "profile";
	}
	getExtends = () =>
	{
		return null;
	}
	onChange = (state, m_change) =>
	{
		console.log( "profile" );
		console.log( state );
		delete state.avatar;
		delete state.roles;
		delete state.sub;
		delete state.__typename;


		if(this.state.email === "")
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "error", 
				message: __("email not be empty")		
			});
			return;
		}
		if(this.state.name === "")
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "error", 
				message: __("name not be empty")		
			});
			return;
		}
		// if(this.state.password === "")
		// {
		// 	AppToaster.show({
		// 		intent: Intent.DANGER,
		// 		icon: "error",
		// 		message: __("password not be empty")
		// 	});
		// 	return;
		// }

		m_change({
			variables:
				{
					input: state
				},
			update: (store, { data: { registerUser } }) =>
			{
				AppToaster.show({
					intent: Intent.SUCCESS,
					icon: "tick",
					message: __("User update")
				});
			}
		})

	}
}
export default compose(
	withApollo,
	withRouter
)(ProfileState);