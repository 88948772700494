import React, {Component, Fragment} from 'react';
// import SubMenuComponent from "./SubMenuComponent";
import i18n from "../layouts/i18n";
import { loader } from 'graphql.macro';
import Loading from "../layouts/utilities/Loading";
import { withRouter} from 'react-router';

import {compose, graphql} from "react-apollo";

class Aside extends Component
{

    constructor(props)
    {
        super(props);
    }
    render()
    {
		if (this.props.data.loading) return <Loading/>;
		let mobile;
		// console.log( this.props.location.state );
		if( this.props.location.state )
			if( 
				this.props.location.state.uniq == "teatchers" 
				|| this.props.location.state.parent_id == "matherials" 
				|| this.props.location.state.post_title == i18n.t("Matherials")
				|| this.props.location.state.uniq == "olympiads"
				|| ( this.props.location.state.matherials && this.props.location.state.matherials.length > 0 )
			)
				mobile = this.getMobile();
        return <Fragment>
            <div className="aside"  >

            </div>
            {mobile}
        </Fragment>
    }
	getMobile = () =>
	{
		return <div className="aside"  >
			<div className="title">
				<i className="fas fa-mobile-alt mr-2"></i>
				{i18n.t("Mobile application")}
			</div>
			<div className="content small">
				{i18n.t("Get ready for olympiads with the mobile Russian-language version of Campbell biology - the best textbook on general biology, the translation of which into Russian is first made by the KPD-BIO team. The application also contains a translation of two additional books Study Guide and Inquary in Action, as well as a module of electronic tests with which you can test your knowledge. The full version of the application is available only to pupils and teachers of Moscow schools (according to the agreement with the copyright holder).")}
			</div>
			<div className="content">
				
			</div>
		</div>
	}
}

Aside.propTypes = {
}

Aside.defaultProps = {
};


export default compose(
	withRouter
 )(Aside)

