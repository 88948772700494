import React, {Component, Fragment} from "react";
import {__} from "../../layouts/utilities/i18n";
import Loading from "../../layouts/utilities/Loading";
import Moment from 'react-moment';
import moment from 'moment';
import $ from "jquery";
import { AnchorButton, Button, ButtonGroup, Classes, Dialog, Intent, Tooltip } from "@blueprintjs/core";

import {compose} from "recompose";
import { loader } from 'graphql.macro';
import { Query, withApollo, graphql } from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";
const getCurrentUser = loader("./graphql/getCurrentUser.graphql");


class ClusterDialog extends Component
{
	/*PROPS:
		toggleDialogCluster
		getPlaceDialog
		getEventDialog
		getTeamDialog
		onNew
	*/	
	state ={ 
		clusterTitle:"",
		cluster:[],
		isClusterOpen:false
	}
	componentWillReceiveProps ( nextProps )
	{
		if(nextProps.clusterTitle || nextProps.cluster || nextProps.isClusterOpen)
		{
			this.setState({
				clusterTitle : nextProps.clusterTitle,
				cluster : nextProps.cluster,
				isClusterOpen : nextProps.isClusterOpen
			})
		}
	}
	render()
	{
		if(this.props.getCurrentUser.loading)
			return <Loading />;
		let footer_btns;
		switch(this.state.clusterTitle)
		{
			case __("All Teams"):
				footer_btns = <ButtonGroup>
					<Button
						className="hidden"					
						intent={Intent.SUCCESS}
						icon="plus"
						text={__("new Team on Map")}
						data_type="team"
						onClick={this.onNew}
					/>
					<Button 
						className="not-hidden"
						intent={Intent.SUCCESS}
						icon="plus"
						text={__("new Team")}
						data_type="team"
						onClick={this.onNewNow}
					/>
				</ButtonGroup>
				break;
			case __("All Events"):
				footer_btns =  <ButtonGroup className="hidden">
					<Button 
						intent={Intent.DANGER}
						icon="plus"
						text={__("new Event on Map")}
						data_type="event"
						onClick={this.onNew}
					/>
					<Button 
						intent={Intent.DANGER}
						icon="plus"
						text={__("new Event into Place")}
						data_type="event"
						className="hidden"
						onClick={this.onNewInPlace}
					/>
				</ButtonGroup>
				break;
			case __("All Places"):
				footer_btns = <Button 
					intent={Intent.PRIMARY}
					icon="plus"
					text={__("new Place")}
					data_type="place"
					className="not-hidden"
					onClick={this.onNew}
				/>
				break;
		}
		return <Dialog
				icon="map-marker"
				isOpen={ this.state.isClusterOpen }
				onClose={ this.props.toggleDialogCluster }
				title={<div>{ __(this.state.clusterTitle) }</div>}
			>
				<div className="p-0 cluster-dialog">
					<ul className="list-group">
					{
						Array.isArray(this.state.cluster) 
							? 
							this.state.cluster.map((e, i) => 
							{
								let sap;
								switch(e.data_type)
								{
									case "place":
										sap = "getPlaceDialog"
										break;
									case "event":
										sap = "getEventDialog"
										break;
									case "team":
										sap = "getTeamDialog"
										break;
								}
								return <li 
									className="list-group-item pointer"  
									onClick={()=> { this.props[sap](e.data_id) }}									
									key={i}
								>
									<div 
										dangerouslySetInnerHTML={{ __html : e.balloonContentHeader}}
									/>
								</li>
							})
							:
							""
					}
					</ul>
				</div>
				<div className="p-4 cluster-dialog-footer">
					{ this.props.getCurrentUser.getCurrentUser ? footer_btns : null }
				</div>
			</Dialog>
	}
	onNewNow = env =>
	{
		const data_type = env.currentTarget.getAttribute("data_type");
		this.props.onStartCreate({
			title:__("new Team"),
			DNDType: "Team"
		});
	}
	onNew = env =>
	{
		const data_type = env.currentTarget.getAttribute("data_type");
		//console.log(env.clientX, env.clientY);
		let style = {}, type;
		switch (data_type)
		{
			case "team":
				style = {backgroundColor:"#28a745"};
				type = "Team";
				break;
			case "event":
				style = {backgroundColor:"#ed4543"};
				type = "Event";
				break;
			case "place":
			default:
				style = {backgroundColor:"#3b5998"};
				type = "Place";
				break;
		}
		this.props.onNew({
			x		: env.clientX,
			y		: env.clientY,
			DNDType	: type,
			DNDStyle: style,
			isDragging:true
		})
		/**/
	}
	onNewInPlace = evt =>
	{
		
	}
}
export default compose(
    graphql( getCurrentUser, {"name" : "getCurrentUser"} ),
	withApollo,
	withRouter
)(ClusterDialog);