export default class CurrentUser 
{
	static _id;
	static first_name ;
	static sub ;
	static roles; 
	static email; 
	static phone_number;  
	static telegram_id; 
	static vk_id ;
	static middle_name; 
	static family_name; 
	static birthdate ;
	static updated_at; 
	static gender;
	static getData(data)
	{
		//console.log(data);
		CurrentUser._id 			= data._id;
		CurrentUser.first_name 		= data.name;
		CurrentUser.family_name 	= data.family_name;
		CurrentUser.roles 			= data.roles;
		CurrentUser.gender 			= data.gender;
		CurrentUser.email 			= data.email;
	}
	static intersectionRole(roles)
	{
		return CurrentUser.roles ? CurrentUser.roles.filter(value => -1 !== roles.indexOf(value)) : [];
	}
}